import * as React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LoadingIndicator from '../components/LoadingIndicator';
import ServiceList from '../components/ServiceList';

import { ProfileContext } from '../providers/ProfileProvider';
import { ServiceListProvider } from '../providers/ServiceListProvider';

import Page from './Page';

import { signInUrl } from '../constants';

const IndexPage = () => {
  const profile = React.useContext(ProfileContext);

  return (
    <Page>
      {
        /* If the profile has not yet loaded, show a loading indicator. */
        (profile == null) && <Box p={2}><LoadingIndicator /></Box>
      }

      {
        /* If the profile is anonymous, show a sign-in button. */
        (profile !== null) && profile.is_anonymous &&
        <Paper>
          <Box p={2}>
            <Typography variant="body1" gutterBottom={true}>
              This site allows members of the University of Cambridge to manage their access
              to <Link href="https://support.google.com/a/answer/181865">additional
              Google Workspace services</Link>.
            </Typography>
          </Box>

          <Box px={1} pb={1} display="flex" flexDirection="row" justifyContent="flex-end">
            <Button color="primary" component="a" href={signInUrl}>Sign in with Cambridge Account</Button>
          </Box>
        </Paper>
      }

      {
        /* If the profile is non-anonymous, show the current user with a sign out link along with the
         * service list. */
        (profile !== null) && !profile.is_anonymous &&
        <Paper>
          <ServiceListProvider>
            <Box p={2} color="text.secondary">
              <Typography variant="body1">
                By enabling these services you signal your agreement to the corresponding terms of
                service and the <Link href="https://policies.google.com/privacy">Google privacy
                policy</Link>.
              </Typography>
            </Box>

            <Divider />

            <ServiceList disablePadding={true} subheader={
              <ListSubheader>
                Additional Services
              </ListSubheader>
            }/>
          </ServiceListProvider>
        </Paper>
      }
    </Page>
  );
};

export default IndexPage;
