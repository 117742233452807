import * as React from 'react';
import {IService, serviceList} from "../api";

/**
 * React context for the current user's list of services.
 */
export const ServiceListContext = React.createContext<null | IService[]>(null);

export const ServiceListConsumer = ServiceListContext.Consumer;

/**
 * User profile provider.
 */
export const ServiceListProvider: React.FunctionComponent = ({ children }) => {
  const [services, setServices] = React.useState<null | IService[]>(null);
  React.useEffect(() => {
    // TODO: respect pagination. It is likely to be a long time before the list of services is
    // large enough to be paginated.
    serviceList().then(({ results }) => setServices(results));
  }, []);
  return <ServiceListContext.Provider value={services}>{ children }</ServiceListContext.Provider>;
}
