import * as React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import AppBarAvatar from '../components/AppBarAvatar';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  container: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  content: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    maxWidth: theme.spacing(100),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },

  title: {
    flexGrow: 1,
  },

  toolbar: {
    ...theme.mixins.toolbar,
  },

  footer: {
    padding: '5px',
    bottom: '0px',
    right: '0px',
    position: 'fixed',
  }
})

interface IProps extends WithStyles<typeof styles> {
  /** child elements */
  children?: React.ReactNode;
}

export const Page = withStyles(styles)(({ children, classes } : IProps) => (
  <div className={ classes.root }>
    <AppBar color="primary">
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.title}>
          Google Workspace Service Preferences
        </Typography>
        <AppBarAvatar IconButtonProps={{edge: "end"}} />
      </Toolbar>
    </AppBar>
    { /* Padding element so that content doesn't appear "under" the AppBar. */ }
    <div className={ classes.toolbar }></div>
    <div className={ classes.container }>
      <div className={ classes.content }>
        { children }
      </div>
    </div>
    <div className={ classes.footer }>made with ❤ by UIS Devops
    </div>
  </div>
));

export default Page;
