import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Warning';
import DeniedIcon from '@material-ui/icons/Block';

import { SnackbarContext } from '../providers/SnackbarProvider';

import { IService, serviceGet, serviceUpdate } from '../api';

export interface IProps extends SwitchProps {
  serviceId: string;
}

export const ServiceSwitch: React.FunctionComponent<IProps> = (
  { serviceId, ...switchProps }
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [service, setService] = React.useState<IService | null>(null);
  const setSnackbarState = React.useContext(SnackbarContext);

  // When the service id changes, fetch the service info from the API.
  React.useEffect(() => {
    setIsLoading(true);
    serviceGet(serviceId)
      .then(setService)
      .catch(() => setError('Could not retrieve opt-in status'))
      .finally(() => setIsLoading(false));
  }, [serviceId]);

  // If there was an error, show the error icon
  if(error !== null) {
    return <Tooltip title={error}><ErrorIcon color="error" /></Tooltip>;
  }

  // If loading, set the loading indicator.
  if(isLoading) {
    return <CircularProgress size={20} />;
  }

  // If the service is null, render nothing. This shouldn't happen normally but be defensive.
  if(service === null) {
    return null;
  }

  // If there are missing capabilities, show the deny icon with a diagnostic tooltip.
  // (We assume that the service description provides a human-friendly description of eligibility.)
  if(service.missing_capabilities.length > 0) {
    const title = <>
      To use this service you must
      {
        service.missing_capabilities.map((capability, index) => <>
          <strong>{' '}{capability.diagnostic}</strong>
          { (index < service.missing_capabilities.length-2) ? ',' : null }
          { (index === service.missing_capabilities.length-2) ? ' and' : null }
          { (index === service.missing_capabilities.length-1) ? '.' : null }
        </>)
      }
    </>;
    return <Tooltip title={title}><DeniedIcon color="action" /></Tooltip>;
  }

  // Render an opt-in switch for the service.
  return <Switch
    { ...switchProps }
    checked={service.current_user_opted_in}
    disabled={isUpdating}
    onChange={event => {
      const servicePatch = { current_user_opted_in: event.target.checked };
      setIsUpdating(true);
      setService({ ...service, ...servicePatch });
      serviceUpdate(serviceId, servicePatch)
        .then(setService)
        .catch(() => setSnackbarState({ message: 'There was an error setting your preference' }))
        .finally(() => setIsUpdating(false));
    }}
  />;
}

export default ServiceSwitch;
