// Interact with the services webapp backend API.

// Get Django's CSRF token from the page from the first element named "csrfmiddlewaretoken". If no
// such element is present, the token is empty.
const CSRF_ELEMENT =
  (document.getElementsByName('csrfmiddlewaretoken')[0] as HTMLInputElement);
const CSRF_TOKEN = (typeof(CSRF_ELEMENT) !== 'undefined') ? CSRF_ELEMENT.value : '';

/** Headers to send with fetch request. */
const API_HEADERS = {
  'Content-Type': 'application/json',
  'X-CSRFToken': CSRF_TOKEN,
};

/** Base for API endpoints. */
const API_BASE = window.location.protocol + '//' + window.location.host + '/api'

/** The various API endpoints */
export const API_ENDPOINTS = {
  serviceList: API_BASE + '/services/',
  profile: API_BASE + '/profile/me/',
};

/**
 * A wrapper around fetch() which performs an API request. Returns a Promise which is resolved with
 * the decoded JSON body of the response (unless method is DELETE) or which is rejected in case of
 * an error.
 *
 * Any errors are *always* logged via console.error().
 */
export const apiFetch = (
  input: string | Request, init: RequestInit = {}
): Promise<any> => (
  fetch(input, {
    credentials: 'include',
    ...init,
    headers: {
      ...API_HEADERS,
      ...init.headers,
    }
  })
  .then(response => {
    if(!response || !response.ok) {
      // Always log any API errors we get.
      // tslint:disable-next-line:no-console
      console.error('API error response:', response);

      // Reject the call passing the response parsed as JSON.
      return response.json().then(body => Promise.reject({
        body,
        error: new Error('API request returned error response'),
      }))
    }

    // Parse response body as JSON (unless it was a delete).

    if (init.method === 'DELETE') {
      return null;
    }
    return response.json()
  })
  .catch(error => {
    // Chain to the next error handler
    return Promise.reject(error);
  })
);

/**
 * Interface for a profile object returned from the API.
 */
export interface IProfile {
  is_anonymous: boolean;
  username: string;
  display_name: string;
  email: string;
  avatar_url: string | null;
}

/** Fetch the user's profile. */
export const profileGet = (): Promise<IProfile> => apiFetch(API_ENDPOINTS.profile);

/**
 * Interface object representing the mutable fields of a service returned
 * from the API.
 */
export interface IServiceMutable {
  current_user_opted_in: boolean;
};

/** Interface for a link associated with a service. */
export interface IServiceLink {
  description: string;
  target_url: string;
}

/**
 * Interface for a capability returned from the API.
 */
export interface ICapability {
  name: string;
  diagnostic: string;
}

/**
 * Interface for a service object returned from the API.
 */
export interface IService extends IServiceMutable {
  id: string;
  url: string;
  description: string;
  long_description: string;
  links: IServiceLink[];
  is_hidden: boolean;
  missing_capabilities: ICapability[];
}

/**
 * Interface for a response from the services list API.
 */
export interface IServiceListResponse {
  next?: string;
  previous?: string;
  results: IService[];
}

/**
 * Interface for a query fo the services list API.
 */
export interface IServiceListQuery { }

/** Fetch a list of services. */
export const serviceList = (
  query: IServiceListQuery = {},
  endpointUrl: string = API_ENDPOINTS.serviceList
): Promise<IServiceListResponse> => apiFetch(appendQuery(endpointUrl, query));

/** Get user's service. */
export const serviceGet = (
  id: string, endpointUrl: string = API_ENDPOINTS.serviceList
) : Promise<IService> => apiFetch(`${endpointUrl}${id}/`);

/** Update user's service. */
export const serviceUpdate = (
  id: string, body: IServiceMutable,
  endpointUrl: string = API_ENDPOINTS.serviceList
) : Promise<IService> => apiFetch(`${endpointUrl}${id}/`, {
  body: JSON.stringify(body),
  method: 'PATCH',
});

/**
 * Append to a URL's query string based on properties from the passed object.
 */
const appendQuery = (endpoint: string, o: {[index: string]: any} = {}): string => {
  const url = new URL(endpoint);
  Object.keys(o).forEach(key => {
    if(o[key] !== undefined) { url.searchParams.append(key, o[key]); }
  });
  return url.href;
}
