import * as React from 'react';

import Snackbar from '@material-ui/core/Snackbar';

interface IProps {
  message?: React.ReactNode;
  action?: React.ReactNode;
}

export type PropsFunction = (props: IProps) => void;

/**
 * React context for the snackbar. The value of the context is a function which will display a
 * message to the user in the snackbar.
 */
export const SnackbarContext = React.createContext<PropsFunction>(props => {});

export const SnackbarConsumer = SnackbarContext.Consumer;

interface IState extends IProps {
  isOpen: boolean;
}

/**
 * Snackbar provider.
 */
export const SnackbarProvider: React.FunctionComponent = ({ children }) => {
  const [{message, action, isOpen}, setState] = React.useState<IState>({ isOpen: false });

  // A function which can be used to set the active props. Will open the snackbar if necessary.
  const setProps: PropsFunction = props => {
    setState({ ...props, isOpen: true });
  }

  return <SnackbarContext.Provider value={setProps}>
    { children }
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      message={message}
      action={action}
      onClose={() => setState({ message, action, isOpen: false })}
    />
  </SnackbarContext.Provider>;
}
