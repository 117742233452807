import * as React from 'react';

import Button from '@material-ui/core/Button';

import { ProfileContext } from '../providers/ProfileProvider';
import { SnackbarContext } from '../providers/SnackbarProvider';

import { signOutUrl } from '../constants';

/**
 * A component which will show a snackbar notification when the user signs in.
 *
 * Must be within a SnackbarProvider component.
 */
export const NotifySignIn = () => {
  const profile = React.useContext(ProfileContext);
  const setSnackbarState = React.useContext(SnackbarContext);
  const previousIsSignedIn = React.useRef<boolean>(false);

  React.useEffect(() => {
    // If user is newly signed in, show snackbar.
    if(profile && !profile.is_anonymous && !previousIsSignedIn.current) {
      const message = <>
        Signed in as <strong>{
          profile.display_name
        }</strong>{
          profile.email && `, ${profile.email}`
        }.
      </>;
      const action = <Button
        color="secondary" size="small" component="a" href={signOutUrl}
      >
        Sign out
      </Button>

      setSnackbarState({message, action});
    }

    // Record sign in value in previousIsSignedIn ref
    previousIsSignedIn.current = Boolean(profile && !profile.is_anonymous);
  }, [previousIsSignedIn, profile, setSnackbarState]);

  return null;
};

export default NotifySignIn;
