import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import IconButton, { IconButtonProps as IconButtonPropsType } from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { ProfileContext } from '../providers/ProfileProvider';

import { signOutUrl } from '../constants';

const styles = (theme: Theme) => createStyles({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    }
  },
});

export interface IProps extends WithStyles<typeof styles> {
  IconButtonProps?: IconButtonPropsType;
}

export const AppBarAvatar = withStyles(styles)(({ IconButtonProps, classes }: IProps) => {
  const profile = React.useContext(ProfileContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  /* Don't render anything if there's no profile or the profile is for the anonymous user. */
  if((profile == null) || (profile.is_anonymous)) { return null; }

  return <>
    <Tooltip title={profile.display_name}>
      <IconButton {...IconButtonProps} onClick={event => setAnchorEl(event.currentTarget)}>
        <Avatar
          alt={profile.display_name} src={profile.avatar_url || undefined} className={classes.avatar}
        >
          { profile.username[0].toUpperCase() }
        </Avatar>
      </IconButton>
    </Tooltip>
    <Menu
      id="menu-avatar"
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      keepMounted={true}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem component="a" href={signOutUrl}>Sign out</MenuItem>
    </Menu>
  </>;
});

export default AppBarAvatar;
