// Adapted from
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/blog/Markdown.js

import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    marginTop: theme.spacing(1),
  },
}));

/** Custom variant of <li> for lists. */
const CustomLi: React.FunctionComponent<object> = props => {
  const classes = useStyles();
  return (
    <li className={classes.listItem}>
      <Typography component="span" {...props} />
    </li>
  );
};

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link, props: { color: 'inherit', underline: 'always' } },
    li: {
      component: CustomLi,
    },
  },
};

/**
 * Render markdown content using Material UI's support for typography. Note that *NO SANITATION IS
 * PERFORMED ON THE MARKDOWN* so do not use this for untrusted user input. The default style is to
 * use the "body1" variant form Material UI's Typography component.
 */
export const Markdown = (props: React.ComponentProps<typeof ReactMarkdown>) => (
  <Typography color="inherit" variant="body1" component="span">
    <ReactMarkdown options={options} {...props} />
  </Typography>
);

export default Markdown;
