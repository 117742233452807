import * as React from 'react';

import List, { ListProps } from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import LoadingIndicator from '../components/LoadingIndicator';
import ServiceSwitch from '../components/ServiceSwitch';
import Markdown from '../components/Markdown';

import Typography from '@material-ui/core/Typography';

import { ServiceListContext } from '../providers/ServiceListProvider';

import { IService } from '../api';

const ServiceListItemSecondary = ({ service }: { service: IService }) => {
  const { links = [] } = service;

  return <>
    {
      service.long_description &&
      <Typography gutterBottom={true}><Markdown>{service.long_description}</Markdown></Typography>
    }
    {
      links.map(({ description, target_url }, index) => <span key={index}>
        <Link href={target_url}>{ description }</Link>
        { index !== (links.length - 1) && ', ' }
      </span>
    )
  }</>;
};

export const ServiceList: React.SFC<ListProps> = (props) => {
  const services = React.useContext(ServiceListContext);

  return <>
    {
      /* While the service list is loading, show the loading indicator. */
      (services == null) && <Box p={2}><LoadingIndicator /></Box>
    }
    {
      /* Once the service list has loaded, render UI for each service. */
      (services !== null) && <List {...props}>{
        services.map((service, index) => (
          <ListItem key={index}>
            {service.is_hidden &&
            <Tooltip title="Service hidden from non-admin users" aria-label="service hidden">
              <ListItemIcon>
                <VisibilityOffIcon />
              </ListItemIcon>
            </Tooltip>
            }
            <ListItemText
              primary={service.description}
              secondary={<ServiceListItemSecondary service={service} />}
            />
            <ListItemSecondaryAction>
              <ServiceSwitch serviceId={service.id} edge="end" color="primary"/>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      }</List>
    }
  </>;
};

export default ServiceList;
