// Polyfill missing features for older IEs and the browsers listed in the browserslist field in
// package.json.
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route } from 'react-router-dom'

import NotifySignIn from './components/NotifySignIn';

import { ProfileProvider } from './providers/ProfileProvider';
import { SnackbarProvider } from './providers/SnackbarProvider';

import IndexPage from './pages/IndexPage';

import theme from './theme';

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ProfileProvider>
      <SnackbarProvider>
        <BrowserRouter>
          { /* A default title for the page which can be overridden by specific pages. */ }
          <Helmet><title>Google Workspace Preferences</title></Helmet>
          <CssBaseline />
          <Route exact={true} path="/" component={IndexPage} />
        </BrowserRouter>
        <NotifySignIn />
      </SnackbarProvider>
    </ProfileProvider>
  </MuiThemeProvider>,
  document.getElementById('app')
);
